import React, { Component, Fragment } from 'react'
import Select from 'react-select';
import madridData from './data'
import { navigate } from "gatsby"

const options = madridData.map(d => ({
  "value" : d.id,
  "label" : d.active
}))

const styles = {
    control: base => ({
      ...base,
      "&:hover": {
        borderColor: "#adb5bd"
      },
      "&:focus": {
        borderColor: "#adb5bd"
      },
      "&:active": {
        borderColor: "#adb5bd"
      },
      boxShadow: "none",
      borderColor: "#adb5bd"
    }),
    option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#ced4da"
    },
    "&:focus": {
      backgroundColor: "#ced4da"
    },
    "&:active": {
      backgroundColor: "#ced4da"
    },
    backgroundColor: state.isSelected ? '#ced4da' : 'transparent',
  })
  };

export default class SelectProject extends Component {
  state = {
    selectedOption: null
  };
  handleChange = selectedOption => {
    this.setState({ selectedOption });
    navigate(`/app/madrid/profile/${selectedOption.value}`)
  };
  render() {
    return (
      <Fragment>
        <Select
          options={options}
          value={this.state.selectedOption}
          onChange={this.handleChange}
          closeMenuOnSelect={false}
          placeholder={'Proyectos'}
          styles={styles}
        />
      </Fragment>
    );
  }
}
